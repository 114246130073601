<template lang="html">
    <div class="purchase-wrap">
            <div class="container pt-3">
                <div class="purchase-card mt-5">
                <h3><span class="badge badge-primary">推薦方案</span></h3>
                <div class="row">
                    <div class="col-md-6 pricing-box">
                    <div class="discount-title"><span class="coin">$</span><span>1,999</span><span class="currency">TWD</span></div>
                    <div class="original-title d-flex-center">原價$2,499</div>
                    </div>
                    <div class="col-md-6 coupon-box">
                        <div class="input-group">
                            <label class="w-100" for="validationServer01">優惠代碼</label>
                            <input id="validationServer01" type="text" class="form-control" placeholder="請輸入5~10碼優惠序號">
                            <div class="input-group-append">
                            <button class="btn btn-secondary" type="button">
                                使用
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <h5>換取金幣30000個，約可看100部教材</h5>
                <p>＊金幣使用期限6個月到期後，系統會自動幫你挑選教材</p>
                <div class="row">
                    <div class="col-md-12 purchase-title mb-2 mt-1">學員資訊</div>
                    <div class="col-md-4">
                        <fieldset disabled>
                            <div class="form-group">
                                <input type="text" id="disabledTextInput" class="form-control" placeholder="garyfree2013@gmail.com">
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" id="" class="form-control remind" placeholder="請輸入名稱">
                            <span class="remind-text">請輸入名稱</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-primary-text p-0">更換其他帳號</button>
                    </div>
                    <div class="col-md-12 purchase-title mb-2 mt-1">付款資訊</div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" id="" class="form-control remind" placeholder="付款人姓名">
                            <span class="remind-text">請輸入付款人姓名</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" id="" class="form-control remind" placeholder="付款人電話">
                            <span class="remind-text">請輸入付款人電話</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                          <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">付款人同學員</label>
                          </div>
                    </div>
                    <div class="col-md-12 purchase-title mb-2 mt-1">付款方式</div>
                    <div class="col-md-12 flex-start">
                            <div class="form-check">
                              <div class="radio">
                                <label>
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                    信用卡付款丶ATM轉帳
                                    <span class="checkmark"></span>
                                </label>
                                </div>
                            </div>
                            <div class="form-check ml-3">
                              <div class="radio">
                                <label>
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                    支付寶丶微信支付
                                    <span class="checkmark"></span>
                                </label>
                                </div>
                            </div>
                    </div>
                    <!-- 發票資訊 -->
                    <div class="col-md-12 purchase-title mb-2 mt-3">發票資訊</div>
                    <div class="col-md-12 flex-start">
                            <div class="form-check">
                              <div class="radio">
                                <label>
                                <input @click="invoicePersonal" class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios1" value="option3" checked>
                                    紙本二聯式發票(個人)
                                    <span class="checkmark"></span>
                                </label>
                                </div>
                            </div>
                            <div class="form-check ml-3">
                              <div class="radio">
                                <label>
                                <input @click="invoiceCompany" class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="option4">
                                    紙本三聯式發票(公司)
                                    <span class="checkmark"></span>
                                </label>
                                </div>
                            </div>
                            <!-- <div class="form-check">
                                <input @click="invoicePersonal" class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios1" value="option3" checked>
                                <label class="form-check-label" for="exampleRadios1">
                                    紙本二聯式發票(個人)
                                </label>
                            </div>
                            <div class="form-check ml-3">
                                <input @click="invoiceCompany" class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="option4">
                                <label class="form-check-label" for="exampleRadios2">
                                    紙本三聯式發票(公司)
                                </label>
                            </div> -->
                    </div>
                    <div class="w-100 mt-3" :class="{ hideblock : invoiceOpen }">
                        <div class="col-md-4">
                          <input type="text" id="" class="form-control remind" placeholder="發票填寫地址或填寫不需要寄送">
                          <span class="remind-text">請輸入發票寄送地址或填寫不需要寄送</span>
                        </div>
                    </div>
                    <div class="hideblock col-md-12 mt-3" :class="{ openblock : invoiceOpen }">
                      <div class="row">
                        <div class="col-md-4">
                          <input type="text" id="" class="form-control remind" placeholder="發票填寫地址或填寫不需要寄送">
                          <span class="remind-text">請輸入發票寄送地址或填寫不需要寄送</span>
                        </div>
                        <div class="col-md-4">
                          <input type="text" id="" class="form-control remind" placeholder="公司抬頭">
                          <span class="remind-text">請輸入公司抬頭</span>
                        </div>
                        <div class="col-md-4">
                          <input type="text" id="" class="form-control remind" placeholder="統一編號">
                          <span class="remind-text">請輸入公司統一編號</span>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex-center agree-text">
                  完成購買程序,即代表您同意這些<a href="#">說明絛款</a>
                </div>
                <div class="d-flex-center agree-text">
                    <button class="btn btn-second">確認</button>
                </div>
            </div>
        </div>
        <!-- 藍新 sample code -->
        <!--
            <h1>結帳頁</h1>
            <button class="btn btn-primary" @click="submitMPG" :disable="!mpgParams">Purchase</button>
            <form v-if="mpgParams" id="mpgForm" name='Newebpay' method='post' 
                  :action='mpgParams.Action'>
                <input type='hidden' name='MerchantID' :value='mpgParams.MerchantID'>
                <input type='hidden' name='TradeInfo' :value='mpgParams.TradeInfo'>
                <input type='hidden' name='TradeSha' :value='mpgParams.TradeSha'>
                <input type='hidden' name='Version' :value='mpgParams.Version'>
            </form>
        -->
    </div>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                default: null
            },
            
        },
        components: {},
        data: function () {
            return {
                mpgParams: null,
                invoiceOpen: false,
            };
        },
        methods: {
            submitMPG: function () {
                $('#mpgForm').submit();
            },
            getMPGParams: async function () {
                try {
                    let res = await this.axios.post("Product/GetMPGParams", {
                    });

                    let data = res.data;
                    if (data.success) {
                        this.mpgParams = data.data;
                    } else {
                        this.alert(data.message);
                    }
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.alert('無法取得MPG參數設定。發生不知名的錯誤');
                }
                this.ticketsLoading = false;
            },
            initData: function () {
                this.getMPGParams();
            }
        },
        mounted: function () {
            this.initData();
        },
        watch: {},
        created() { }
    };
</script>


<style>
.purchase-wrap {
  background-color: #f7f8fa;
  padding-bottom: 40px;
}

.purchase-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
}

.badge-primary {
  background-color: #fd6b69;
}

.purchase-wrap .discount-title {
  color: #484848;
  font-size: 4.9rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-family: "Arial";
}

.purchase-wrap .currency {
  font-size: 1.4rem;
  margin-left: 5px;
}

.purchase-wrap .coin {
  font-size: 2.2rem;
  margin-right: 3px;
}

.original-title {
  color: #b5b5b5;
  font-size: 1.7rem;
  text-decoration: line-through;
  padding: 8px 20px 0px 20px;
}

.pricing-box {
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.coupon-box {
  display: inline-flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 28px;
  flex-wrap: wrap;
}

.purchase-wrap .form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.purchase-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.purchase-wrap h5 {
  color: #484848;
}

.purchase-wrap p {
  color: #ff5151;
  padding-bottom: 40px;
  border-bottom: 5px solid #ff5151;
}

.btn-primary-text {
  color: #3559ce !important;
}

.remind {
  width: 100%;
  background-color: transparent;
  display: block;
  padding: 5px 10px;
  border: 2px solid rgb(240, 80, 80);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.75;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  transition: all 0.2s ease 0s;
}

.remind-text {
  color: rgb(240, 80, 80);
  margin-top: 10px;
  font-size: 0.9rem;
}

/* Styles for wrapping the search box */

.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn-secondary {
  color: #fff;
  background-color: #3559ce;
  border-color: #3559ce;
  height: 38px;
}

.hideblock {
  display: none;
}

.openblock {
  display: block;
}

.agree-text {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 20px;
}

.agree-text a {
  color: #3559ce;
}

/* 單選radio */
.radio {
  position: relative;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 26px;
  width: 26px;
  border: 2px solid #3559ce !important;
  border-radius: 50%;
}

/* .radio:hover input ~ .checkmark {
  background-color: #ccc;
} */

.radio input:checked ~ .checkmark {
  border: 2px solid;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3559ce !important;
}
.radio label {
  min-height: 20px;
  padding-left: 35px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0rem;
}
</style>